.font-size-title{
    font-size: 14px;
}

.font-size-primary{
    font-size: 14px;
}

.font-size-secondary{
    font-size: 12px;
}

.fade-in-500ms {
    animation: fadeIn ease 500ms;
    -webkit-animation: fadeIn ease 500ms;
    -moz-animation: fadeIn ease 500ms;
    -o-animation: fadeIn ease 500ms;
    -ms-animation: fadeIn ease 500ms;
}

.fade-in-1s {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}
.fade-in-2s {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

.newSchedule {
    animation: changeSuccess 5s;
}

.updatedSchedule{
    animation: changeSuccess 1s;
}

@keyframes changeSuccess {
    from {background-color: palegreen;}
    to {background-color: white;}
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dragContainer {
    width: 100%;
    margin: 0 auto;
}

.dragHandler {
    padding: 1px 5px;
    color: #000;
    background: #ffffff;
    border-radius: 3px;
    cursor: grab;
    border: 1px solid;
}

.dragElement {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
